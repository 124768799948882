import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import DefaultLayout from '@Layouts/Default';
import ArticleDescription from '@components/ArticleDescription';
import Breadcrumbs from '@components/Breadcrumbs';
import { Container, Row, Col } from '@Omegapoint/components/layout';
import { Text } from '@Omegapoint/components';
import PriceSwitch from '@components/PriceSwitch';
import ArticleList from '@components/ArticleList';
import { LIST_VIEW } from '@enums';
import useTranslate from '@hooks/Translation';
import { getTheme } from '@styles/global';
import useStore from '@store';
import { htmlParser } from '@utilities';
import LoginModal from '@Omegapoint/components/Auth/LoginModal';
import Spinner from '@components/atoms/Spinner';

const ArticlePage = (props) => {
  const { data, location, pageContext } = props;
  const { translate } = useTranslate();
  const auth = useStore((state) => state.auth);
  const authActions = useStore((state) => state.authActions);

  const [showArticlePage, setShowArticlePage] = useState(false);

  useEffect(() => {
    if (data?.chProduct?.locked) {
      if (
        auth.status === 'none' ||
        (auth.status === 'in' &&
          typeof auth.user?.accountNumber === 'undefined')
      )
        authActions.getCurrentUserExtended();
      else if (auth.status !== 'in') navigate('/');
      else if (
        auth.status === 'in' &&
        typeof auth.user?.accountNumber !== 'undefined'
      ) {
        if (
          data?.chProduct.costumerAccess?.some(
            (x) => x.trim() == auth.user?.accountNumber
          )
        )
          navigate('/');
        else setShowArticlePage(true);
      }
    } else {
      setShowArticlePage(true);
    }
  }, [auth.status]);

  if (typeof data === 'undefined' || !showArticlePage) return null;

  const { SiteSettings } = data.allUmbracoMarkets.edges
    .filter((edge) => {
      return (
        edge.node.Culture !== null &&
        edge.node.Culture.countryCode === pageContext.culture
      );
    })
    .map((page) => {
      return page.node.UmbracoContent.Settings;
    })[0];

  const theme = getTheme(SiteSettings?.SiteTheme.toLowerCase());

  const relatedArticles = data?.chProduct?.articleUrls
    .map((articleUrl) => {
      return {
        mainImage: {
          original: articleUrl.chArticle?.mainImage?.imageFormats?.thumbnail,
          optimized:
            articleUrl.chArticle?.mainImage?.imageFormats?.thumbnailOptimized,
        },
        itemName: htmlParser(articleUrl.chArticle?.title),
        itemWidth: articleUrl.chArticle?.packaging?.primary?.width,
        itemHeight: articleUrl.chArticle?.packaging?.primary?.height,
        itemDepth: articleUrl.chArticle?.packaging?.primary?.depth,
        itemMaterial: articleUrl.chArticle?.attributes?.material?.Value,
        variant: 'card',
        ean: articleUrl.chArticle?.packaging?.primary?.gtin,
        sku: articleUrl.chArticle?.sku,
        inStock: true,
        url: articleUrl?.path,
        id: articleUrl.chArticle?.sku,
        isNew: articleUrl?.chArticle?.isNew,
        itemUnit: articleUrl.chArticle?.packaging?.primary?.unit,
        itemUnitQuantity: articleUrl.chArticle?.packaging?.primary?.quantity,
      };
    })
    .filter((article) => article?.sku !== data?.chArticle?.sku);

  return (
    <DefaultLayout
      culture={props.pageContext.culture}
      canonical={props.pageContext.canonical ?? props.path}
      title={htmlParser(data.chArticle?.title)}
      description={htmlParser(data.chArticle?.description)}
      path={props.path}
    >
      {process.env.GATSBY_REQUIRE_LOGIN === 'true' && auth.status !== 'in' ? (
        <>
          {auth.status === 'loading' || auth.status === 'none' ? (
            <Spinner />
          ) : (
            <LoginModal culture={props.pageContext.culture} />
          )}
        </>
      ) : (
        <>
          <Container gutter="20">
            <Row gutters={theme.Variables.gutter}>
              <Col col={12} xl={7}>
                <Breadcrumbs
                  path={location.pathname}
                  parts={data.chProduct?.breadcrumb}
                  hideSecondLast
                />
              </Col>
              {auth?.status === 'in' && (
                <Col col={12} xl={5}>
                  <PriceSwitch />
                </Col>
              )}
            </Row>
          </Container>
          <ArticleDescription
            title={htmlParser(data.chArticle?.title)}
            image={{
              original: data.chArticle?.mainImage?.imageFormats?.webColor,
              optimized:
                data.chArticle?.mainImage?.imageFormats?.webColorOptimized,
            }}
            width={data.chArticle?.packaging?.primary?.width}
            height={data.chArticle?.packaging?.primary?.height}
            depth={data.chArticle?.packaging?.primary?.depth}
            gtin={data.chArticle?.packaging?.primary?.gtin}
            sku={data.chArticle?.sku}
            attributes={data.chArticle?.attributes}
            description={htmlParser(data.chArticle?.description)}
            descriptionLong={data.chArticle?.descriptionLong}
            materialDescription={data.chArticle?.attributes.material.Key}
            surfaceDescription={data.chArticle?.attributes.surface.Key}
            inStock={false}
            isNew={data?.chArticle?.isNew}
            additionalImages={data.chArticle?.images}
            unit={data.chArticle?.packaging?.primary?.unit}
            unitQuantity={data.chArticle?.packaging?.primary?.quantity}
            relatedArticles={relatedArticles}
          />
          {relatedArticles && relatedArticles.length > 0 && (
            <Container gutter={theme?.Variables.gutter}>
              <Row>
                <Col col="12">
                  <Text variant="h3" align="center">
                    {translate('Related_Products')}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ArticleList
                    showPages={false}
                    showPagination={false}
                    showFilters={false}
                    articles={relatedArticles}
                    initialView={LIST_VIEW.GRID}
                  />
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}
    </DefaultLayout>
  );
};

export default ArticlePage;

export const query = graphql`
  query ArticlePageQuery(
    $sku: String!
    $culture: String!
    $productEntityId: String!
  ) {
    chArticle(market: { countryCode: { eq: $culture } }, sku: { eq: $sku }) {
      title
      description
      descriptionLong
      sku
      mainImage {
        imageUrl
        imageFormats {
          web
          webOptimized
          webColor
          webColorOptimized
        }
      }
      images {
        imageUrl
        entityId
        imageFormats {
          web
          webOptimized
          webColor
          webColorOptimized
        }
      }
      isNew
      attributes {
        application
        material
        surface
      }
      packaging {
        primary {
          height
          gtin
          depth
          width
          unit
          quantity
        }
      }
    }
    chProduct(
      market: { countryCode: { eq: $culture } }
      entityId: { eq: $productEntityId }
    ) {
      breadcrumb
      locked
      costumerAccess
      articleUrls {
        path
        chArticle {
          isNew
          title
          sku
          mainImage {
            imageFormats {
              thumbnail
              thumbnailOptimized
            }
          }
          attributes {
            material
          }
          packaging {
            primary {
              height
              width
              depth
              gtin
              unit
              quantity
            }
          }
        }
      }
    }
    allUmbracoMarkets {
      edges {
        node {
          Culture {
            countryCode
          }
          UmbracoContent {
            Settings {
              SiteSettings {
                SiteTheme
              }
            }
          }
        }
      }
    }
  }
`;
